<template>
  <v-dialog v-model="dialog"
            width="800px"
            hide-overlay
            transition="dialog-top-transition"
            scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} scheduling request</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  outlined :error="$v.scheduling_request.name.$error"
                  dense
                  placeholder="Name"
                  label="Name"
                  v-model="scheduling_request.name"
              >
                <template v-slot:label>
                  Scheduling request title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.scheduling_request.name.$error">This information is required</span>
              <span class="text-danger" v-if="errors.name" >{{errors.name[0]}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="12" v-if="currentUser.access_type && currentUser.access_type!='score'">
              <v-select
                  outlined
                  dense item-text="name"
                  item-value="id"
                  :items="scores"
                  v-model="scheduling_request.score_id"
              >
                <template v-slot:label>
                  SCORE
                </template>
              </v-select>


            </v-col>
            <v-col cols="12" sm="12" md="12">
              <label class="text-body">
                Select label colour
              </label>
              <v-col>
              <v-swatches v-model="scheduling_request.color"
                          show-fallback
                          swatches="text-advanced"
                          fallback-input-type="color"
                          popover-x="right">

              </v-swatches>
              </v-col>




            </v-col>
            <v-col cols="12" sm="12" md="12">
              <label class="text-body">
                Description
              </label>
              <ckeditor :config="editorConfig"
                  v-model="scheduling_request.description" >
              </ckeditor>
            </v-col>
            <v-col cols="12" sm="12" md="8">
              Text box for enroller comment
              <v-switch
                  v-model="scheduling_request.require_additional_info"
                  :label="scheduling_request.require_additional_info ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              Status
              <v-switch
                  v-model="scheduling_request.is_active"
                  :label="scheduling_request.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            x-large
            @click="closeDialog"
            class="cancel-btn"
        >
          Cancel
        </v-btn>
        <v-btn
            x-large
            dark
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
import SchedulingRequestService from "@/services/practical-exam/scheduling-request/SchedulingRequestService";
import ScoreService from "@/services/score/score/ScoreService";
import {required, requiredIf} from "vuelidate/lib/validators";
const score = new ScoreService();
const scheduleRequest=new SchedulingRequestService();
import VSwatches from 'vue-swatches'



export default {
  validations:{
    scheduling_request:{
      name:{required},
    }
  },
  components: { VSwatches },
  data(){
    return{
      title:'',
      dialog: false,
      edit: false,
      loading: false,
      currentUser:{},
      hex: '',
      scheduling_request:{
        name:'',
        description:'',
        color:'#efefef',
        is_active:true,
        require_additional_info:false,

      },
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
      scores:[],
      errors:[],
    }
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset()
    },
    openDialog() {
      this.dialog = true;
    },
    resetForm(){
      this.scheduling_request={
        title:'',
        description:'',
        color:null,
        is_active:true,
        require_additional_info:false,
      };
    },
    createSchedulingRequest() {

      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Create";
    },
    editSchedulingRequest(item) {
      this.openDialog();
      this.edit = true;
      this.scheduling_request = item;
      this.title = "Edit";
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      this.loading = true;
      scheduleRequest
          .create(this.scheduling_request)
          .then(response => {
            this.$snotify.success("Scheduling request added");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },
    update: function () {
      this.loading = true;
      scheduleRequest
          .update(this.scheduling_request.id, this.scheduling_request)
          .then(response => {
            this.$snotify.success("Scheduling request updated");
            this.$emit('refresh');
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
          })
          .catch(error => {
            this.errors = error.errors;
          })

    },

    getAllScore(){
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    }
  },
  mounted() {
    this.getCurrentUser();
    this.getAllScore();

  },
  computed:{

  }
}
</script>