<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Scheduling requests</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Scheduling requests
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">

                  <v-btn  @click="createSchedulingRequest()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add scheduling request
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Search by scheduling request title"
                      v-on:keyup.enter="searchSchedulingRequest"
                      clearable
                      v-model="search.info"
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6" md="3" v-if="currentUser.access_type!='score'">
                  <v-autocomplete
                      :items="scores"
                      v-model="search.score_id"
                      label="SCORE"
                      item-text="name"
                      item-value="id"
                      outlined v-on:keyup.enter="searchSchedulingRequest"
                      dense clearable
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-autocomplete
                      :items="status"
                      v-model="search.is_active"
                      label="Status" v-on:keyup.enter="searchSchedulingRequest"
                      item-text="name"
                      item-value="value"
                      outlined clearable
                      dense
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" :md="currentUser.access_type == 'score' ? 6 : 3">
                  <v-btn :loading="isLoading"
                      @click="searchSchedulingRequest"
                      class=" btn btn-primary w-35 float-right"
                      dark
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Scheduling request</th>
                 <th class="px-3">Label colour</th>
                  <th class="px-3  w-25">SCORE</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <template>
                  <tr v-if="scheduling_request.length > 0" v-for="item in scheduling_request">
                    <td class="px-2">
                      <a @click="editSchedulingRequest(item)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                       {{item.name }}
                      </a>
                    </td>
                      <td class="px-2">
                        <span class="badge" :style="'background: ' + item.color">
                            {{item.color}}
                        </span>
                      </td>
                    <td class="px-2">
                        {{item.score_name ? item.score_name : 'NA'}}
                    </td>
                    <td>
                      <span class="badge"
                            v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                      >{{ item.status_text }}</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editSchedulingRequest(item)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="!item.has_enrolments">
                              <a  @click="deleteSchedulingRequest(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>

                  <tr v-if="scheduling_request.length == 0">
                    <td colspan="4" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
              </table>
              <v-overlay :value="isSorting">
                <v-progress-circular
                    indeterminate
                    color="dark"
                ></v-progress-circular>
              </v-overlay>
<!--              <b-pagination-->
<!--                  v-if="scheduling_request.length > 0"-->
<!--                  class="pull-right mt-7"-->
<!--                  @input="getAllSchedulingRequest"-->
<!--                  v-model="page"-->
<!--                  :total-rows="total"-->
<!--                  :per-page="perPage"-->
<!--                  first-number-->
<!--                  last-number-->
<!--              ></b-pagination>-->
            </div>
          </div>
        </div>
      </div>
      <create-or-update ref="create-or-update" @refresh="getAllSchedulingRequest"></create-or-update>

    </div>
  </v-app>
</template>
<script>
import SchedulingRequestService from "@/services/practical-exam/scheduling-request/SchedulingRequestService";
import ScoreService from "@/services/score/score/ScoreService";
import CreateOrUpdate from "./CreateOrUpdate";
import draggable from "vuedraggable";

const score = new ScoreService();
const scheduleRequest=new SchedulingRequestService();
export default {
  components:{
    CreateOrUpdate,
    draggable
  },
  data(){
    return{
      search:{
        info:'',
        is_active:'',
        score_id:'',
      },
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      currentUser:{},
      total: null,
      perPage: null,
      page: null,
      scheduling_request:[],
      scores:[],
      isLoading:false,
      isSorting:false,
      drag:false,
    }
  },
  methods:{
    sort(){
      this.isSorting = true;
      scheduleRequest
          .sort(this.scheduling_request)
          .then(response => {
            this.isSorting = false;
            this.$snotify.success('Scheduling request sorted !!');
            this.getAllSchedulingRequest();
          })
          .catch(err => {
            this.isSorting = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    getAllSchedulingRequest(){
      this.isLoading = true;
      scheduleRequest
      .paginate(this.search,this.page)
          .then(response => {
            this.scheduling_request=response.data.data;

            this.isLoading = false;
          })
          .catch((err) => {

          });
    },
    getAllScore(){
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
    deleteSchedulingRequest(item){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            scheduleRequest
                .delete(item)
                .then((response) => {
                  this.getAllSchedulingRequest();
                  this.$snotify.success("Scheduling request deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    searchSchedulingRequest(){
      this.getAllSchedulingRequest();
    },
    createSchedulingRequest(){
      this.$refs['create-or-update'].createSchedulingRequest();
    },
    editSchedulingRequest(item){
      this.$refs['create-or-update'].editSchedulingRequest(item);
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    }
  },
  mounted() {
    this.getCurrentUser();
    this.getAllSchedulingRequest();
    this.getAllScore();
  }
}
</script>